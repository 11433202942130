<template>
  <div class="page flex-col">
    <div class="standard-header flex-col">
      <span class="standard-header_one">技术标准</span>
      <span class="standard-header_two">technical&nbsp;standard</span>
    </div>
    <div class="standard-middle flex-col">
      <div class="anrchor_class" id="standard"></div>
      <div class="standard-middle_title flex-row justify-between">
        <img class="middle_title-img" referrerpolicy="no-referrer"
          :src="decorationIcon" />
        <span class="middle_title-label">技术标准</span>
      </div>
      <div class="standard-middle_group flex-row justify-between">
        <div class="middle_group-box flex-col" v-for="(item, index) in cards" :key="index">
          <div class="group-box_wrapper flex-col">
            <img class="box_wrapper-img" referrerpolicy="no-referrer" :src="item.cardItemIcon" />
          </div>
          <span class="group-box_title">{{ item.cardItemTitle }}</span>
          <div class="group-box_style flex-col"></div>
          <div class="group-box_block flex-col">

            <div class="box_block-div flex-row" v-for="(subItem, subIndex) in item.cardlabels" :key="subIndex">
              <img class="block-div_img" referrerpolicy="no-referrer"
                :src="dotIcon" />
              <span class="block-div_text">{{ subItem }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import decorationIcon from '@/assets/images/technicalStandard/decoration-icon.png';
import dotIcon from '@/assets/images/technicalStandard/dot-icon.png';
import cardOneIcon from '@/assets/images/technicalStandard/card_one.png';
import cardTwoIcon from '@/assets/images/technicalStandard/card_two.png';
import cardThreeIcon from '@/assets/images/technicalStandard/card_three.png';
import cardFourIcon from '@/assets/images/technicalStandard/card_four.png';
import cardFiveIcon from '@/assets/images/technicalStandard/card_five.png';
export default {
  data() {
    return {
      decorationIcon,
      dotIcon,
      cards: [
        {
          cardItemIcon: cardOneIcon,
          cardItemTitle: '电池数字护照码标准',
          cardlabels: [
            '每个电池数字护照的电池数字护照码应具备唯一性。',
            '应由单独可信的生码体系确保电池数字护照码的唯一性、安全性和国际流通性。如工业互联网标识体系。'
          ]
        },
        {
          cardItemIcon: cardTwoIcon,
          cardItemTitle: '电池数字护照数据核验标准',
          cardlabels: [
            '电池数字护照应通过相关技术手段核验其数据的真实性、唯一性以及数据的不可篡改。',
            '将电池投放市场的经济运营主体，可使用区块链技术确保电池数字护照的真实性、唯一性和数据的不可篡改，并通过相关核验平台提供核验数据的技术手段。'
          ]
        },
        {
          cardItemIcon: cardThreeIcon,
          cardItemTitle: '电池数字护照动态数据更新标准',
          cardlabels: [
            '应该至少每天更新一次。并且在特定目的时，需要更频繁地更新。'
          ]
        },
        {
          cardItemIcon: cardFourIcon,
          cardItemTitle: '电池数字护照数据存储期限标准',
          cardlabels: [
            '经济经营者应在与电池数字护照相关的电池上市10年内，保存电池数字护照数据。',
            '同时，在电池报废前维护电池数字护照的链接，使其可正常访问。'
          ]
        },
        {
          cardItemIcon: cardFiveIcon,
          cardItemTitle: '电池数字护照数据访问标准',
          cardlabels: [
            '电池数字护照中包含的所有信息应基于开放标准，并采用互操作格式，可通过开放互操作数据交换网络传输，无供应商网络锁定。',
            '电池数字护照应满足机器可读、结构化和可搜索的要求。'
          ]
        }
      ]
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
