<template>
  <div class="page flex-col">
    <div class="group_faq flex-col">
      <!-- header -->
      <div class="faq_header flex-col">
        <div class="header_body flex-col">
          <div class="body_wrapper">
            <span class="body_text1">关于DPP的问题？请参阅我们的</span>
            <span class="body_text2">中国电池护照技术发展报告</span>
          </div>
        </div>
      </div>
      <!-- 标题 -->
      <div class="faq-title flex-row justify-between">
        <img class="title-icon" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="title-label">常见问题</span>
      </div>
      <img class="faq-image" referrerpolicy="no-referrer" :src="cardBackgroundImg" />
      <div class="faq-block flex-col" v-for="(item, index) in issueList" :key="index">
        <div class="block-wrapper_1 flex-row" @click="collapse(index, item)">
          <span :class="['block_text1_1', item.serialColor ? 'block_text1_color1' : null]">{{ item.serialNo }}</span>
          <span :class="['block_text1_2', item.labelColor ? 'block_text1_color2' : null]">{{ item.label }}</span>
        </div>
        <div class="block-wrapper_2 flex-col" v-if="item.collapsed">
          <p>
            <span class="block_text2_1">答:&nbsp;&nbsp;</span>
            <span class="block_text2_2">{{ item.expendLabel }}</span>
          </p>
        </div>
      </div>
    </div>
    <footer-nav />
  </div>
</template>
<script>
import decorationIcon from '@/assets/images/home/decoration.png';
import cardBackgroundImg from '@/assets/images/faq/card_background.png'
export default {
  data() {
    return {
      decorationIcon,
      cardBackgroundImg,
      serialColor: '#F47422',
      labelColor: '#005685',
      issueList: [
        {
          serialNo: '01',
          label: '什么是产品数字护照?',
          expendLabel: '产品数字护照的本质是一组特定于产品的、可通过数据载体进行电子访问的数据集，通过数据载体链接唯一标识符，在跨国贸易和流通中证明产品的产地、身份及可持续水平。',
        },
        {
          serialNo: '02',
          label: '什么是产品数字护照网?',
          expendLabel: '中国产品数字护照网在中国电池工业协会及中国信通院指导下，由国家工业互联网标识解析二级节点建设单位南京复创建立运营，是构建产业链供应链协同的数据共享空间，是专注中国产品数字护照的技术平台，是中国产品数字护照体系服务的窗口。',
        },
        {
          serialNo: '03',
          label: '中国产品数字护照网目标是什么?',
          expendLabel: '以行业项目为切入点,定义中国产品数字护照体系标准，塑造中国产品数字护照的未来。',
        },
        {
          serialNo: '04',
          label: '如何理解中国产品数字护照体系?',
          expendLabel: '中国产品数字护照体系是在数字化和绿色化协同发展的背景下，整合工业互联网标识、区块链等新一代信息技术，全面考虑产品生命周期管理和可持续发展需求，推出的创新型数字认证系统，对推动产品循环利用和绿色生产、促进产业数字化和绿色化协同发展、推动产业可持续发展和数字创新具有重要作用。',
        },
        {
          serialNo: '05',
          label: '如何加入网站?',
          expendLabel: '联系电话：400-106-2918 联系邮箱：chdpp@fc3de.com',
        },
        {
          serialNo: '06',
          label: '什么是数字护照联合验证项目?',
          expendLabel: '针对企业的产品数字护照部署验证项目。',
        },
        {
          serialNo: '07',
          label: '中国产品数字护照网提供什么样的解决方案?',
          expendLabel: '提供关于产品数字护照的软硬件系统平台及咨询、规划、设计、实施、认证服务的综合解决方案。',
        },
        {
          serialNo: '08',
          label: '目前, 哪些行业需要提供产品数字护照?',
          expendLabel:
            `
          目前、在全球范围内，产品数字护照将在电池、电子产品、纺织、钢铁等行业率先落地。
          《新欧盟电池和废电池法规》中提出，2027年2月18日起，投放市场或投入使用的LMT
          电池、容量大于2kwh的工业电池和电动汽车电池应具有数字护照。
          欧洲议《可持续循环纺织品策略》决议通过，贸易中纺织品DPP强制性使用的启动时间在
          2030年。
          另外，欧盟碳边境调节机制已经推行，钢铁行业作为CBAM重点涉及领域，对于全生命周
          期碳数据管理提出新的更高要求。预计到2030年，欧盟市场上除食品、饲料和医药产品以
          外，包括钢材产品及钢材下游终端产品的所有产品都要满足产品数字护照的要求。
          `,
        }
      ]
    };
  },
  methods: {
    collapse(index, item) {
      let handleItem = null;
      const funcItem = (serialColor, labelColor, collapsed) => {
        return {
          ...item,
          serialColor,
          labelColor,
          collapsed
        }
      }
      if (item.collapsed) {
        handleItem = funcItem(undefined, undefined, false);
      } else {
        handleItem = funcItem(this.serialColor, this.labelColor, true);
      }
      this.issueList = this.issueList.map((element, eleIndex) => eleIndex == index ? handleItem : element);
    }
  },
};
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
