<template>
  <div class="page flex-col">
    <div class="group_policy flex-row">
      <div class="policy_wrapper flex-col">
        <div class="policy_header flex-col">
          <span class="text_1">政策解读</span>
          <span class="text_2">POLICY&nbsp;INTERPRETATION</span>
        </div>
        <!-- 新闻列表 -->
        <div v-for="(item, index) in newsList" :key="index">
          <div class="group_news flex-row" v-if="item.cardUrl">
            <img class="image_card" referrerpolicy="no-referrer" :src="item.cardImg" />
            <div class="card_body flex-col" v-if="item.cardUrl">
              <span class="body_card_title">{{ item.cardTitle }}</span>
              <div class="body_card_date">
                <div class="card_date_body">
                  <div class="date_body_wrapper flex-col">
                    <img class="date_body_image" referrerpolicy="no-referrer" :src="item.cardIcon" />
                  </div>
                  <span class="date_body_text">{{ item.cardDate }}</span>
                </div>
              </div>
              <span class="body_card_content" v-if="item.cardContent">{{ item.cardContent }}</span>
              <div class="body_card_btns flex-col">
                <span type="primary" class="card_btns_text" @click="convertUrl(item.cardUrl)">查看详情</span>
              </div>
            </div>
          </div>
        </div>
        <footer-nav />
      </div>
    </div>
  </div>
</template>
<script>
import timeIcon from '@/assets/images/policyComment/time-icon.png';
import cardImgOne from '@/assets/images/policyComment/card_img_one.png';
import cardImgTwo from '@/assets/images/policyComment/card_img_two.png';
import cardImgThree from '@/assets/images/policyComment/card_img_three.png';
import cardImgFour from '@/assets/images/policyComment/card_img_four.png';
import cardImgFive from '@/assets/images/policyComment/card_img_five.png';
import cardImgSix from '@/assets/images/policyComment/card_img_six.png';
import cardImgSeven from '@/assets/images/policyComment/card_img_seven.png';
import cardImgEight from '@/assets/images/policyComment/card_img_eight.png';
import policyCommentCover from '@/assets/images/policyComment/policyComment-cover.png';
export default {
  data() {
    return {
      newsList: [
        {
          cardImg: policyCommentCover,
          cardTitle: '十五部门联合印发《关于建立碳足迹管理体系的实施方案》，产品数字护照（DPP）推进构建可持续未来',
          cardIcon: timeIcon,
          cardDate: '2023年2月4日  10:00:13',
          cardBtn: '查看详情'
        },
        {
          cardImg: policyCommentCover,
          cardTitle: '欧盟理事会正式通过《可持续产品生态设计条例》，将通过“数字护照”对在售产品强制进行碳追踪',
          cardIcon: timeIcon,
          cardDate: '2023年2月4日  10:00:13',
          cardBtn: '查看详情'
        },
        {
          cardImg: policyCommentCover,
          cardTitle: '欧盟《可持续产品生态设计法规》（ESPR）——数字产品护照要求解读',
          cardIcon: timeIcon,
          cardDate: '2023年2月4日  10:00:13',
          cardBtn: '查看详情'
        },
        {
          cardImg: policyCommentCover,
          cardTitle: '中国电池工业协会八届五次理事会暨电池护照工作推进会圆满召开',
          cardIcon: timeIcon,
          cardDate: '2023年2月4日  10:00:13',
          cardBtn: '查看详情'
        },
        {
          cardImg: policyCommentCover,
          cardTitle: '政策简报｜促进汽车循环的数字车辆护照',
          cardIcon: timeIcon,
          cardDate: '2024年03月21日 19:01:00',
          cardContent: '欧盟委员会于2023年7月发布循环车辆条例草案，旨在推动汽车行业从设计到报废处理全面向循环经济转型。条例第13条提出了循环车辆护照概念，其本质是车辆的数字......',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/T9nY2fjY8jnV-Haug_biVA',
        },
        {
          cardImg: policyCommentCover,
          cardTitle: '关于欧盟《新电池法案》解读之数字电池护照',
          cardIcon: timeIcon,
          cardDate: '2024年01月18日 17:38:00',
          cardContent: '自2027年2月18日起，每一块LMT电池、每一块容量大于2千瓦时的工业电池以及每一块投放市场或投入使用的电动汽车电池都应该有电子记录，即电池护照。',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/ZVy-K4GYNEFKEcS05Jtbrg',
        },
        {
          cardImg: policyCommentCover,
          cardTitle: '欧盟电池法规详解《电池回收》',
          cardIcon: timeIcon,
          cardDate: '2024年01月02日 09:12:55',
          cardContent: '欧洲电池法规于2023年8月17日正式生效，这一法规的核心目标是推动电池产业向更加可持续和环保的方向发展，特别关注循环物料的使用和管理。法规要求电池制造商......',
          cardBtn: '查看详情',
          cardUrl: 'https://cn.solarbe.com/news/20240102/84046.html',
        },
        {
          cardImg: policyCommentCover,
          cardTitle: '关于欧盟《新电池法案》解读之碳足迹',
          cardIcon: timeIcon,
          cardDate: '2023年11月06日 17:50:00',
          cardContent: '2023年8月17日生效的《新电池法案》要求未来在欧盟市场上销售的容量大于2千瓦时的可充电工业电池、轻型交通工具电池（LMT&nbsp;batteries）和电动汽车电池应附有......',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/Ldw7H89iiwE9p6lLkIvTVw',
        }
      ]
    };
  },
  methods: {
    convertUrl(url) {
      window.open(url, '_blank');
    }
  },
};
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
