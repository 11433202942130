<template>
  <div class="page flex-col">
    <div class="box_about flex-col">
      <div class="about_header flex-col">
        <span class="about_header_cn">关于我们</span>
        <span class="about_header_en">ABOUT&nbsp;US</span>
      </div>
      <div class="about_title flex-row justify-between">
        <img class="title_icon" referrerpolicy="no-referrer"
          :src="decorationIcon" />
        <span class="title_label">中国产品数字护照网</span>
      </div>
      <div class="about_card flex-row">
        <div class="card_checks flex-col">
          <img class="card_checks_icon" referrerpolicy="no-referrer"
            :src="correctOrangeIcon" />
          <img class="card_checks_icon" referrerpolicy="no-referrer"
            :src="correctOrangeIcon" />
          <img class="card_checks_icon" referrerpolicy="no-referrer"
            :src="correctOrangeIcon" />
        </div>
        <div class="card_text flex-col justify-between">
          <span class="text_title">面向全球，创建产品数字护照产业生态</span>
          <span class="text_paragraph">
            中国产品数字护照网由中国电池工业协会及中国信通院指导<br />
            由国家工业互联网标识解析二级节点建设单位所建立运营<br />
            专注中国产品数字护照的专业门户，是中国产品数字护照体系服务的客户端统一入口
          </span>
        </div>
        <div class="card_image flex-col">
          <img class="image_card" :src="cardImg" />
          <!-- <img class="image_header" referrerpolicy="no-referrer"
            :src="cardImgHeader" />
          <img class="image_body" referrerpolicy="no-referrer"
            :src="cardImgContent" /> -->
        </div>
      </div>
      <div class="about_title_2 flex-row justify-between">
        <img class="title_2_icon" referrerpolicy="no-referrer"
             :src="decorationIcon" />
        <span class="title_2_label">公开活动</span>
      </div>
      <!-- 新闻列表 -->
    <div>
      <div class="group_news flex-row" v-for="(item, index) in newsList" :key="index">
        <img class="image_card" referrerpolicy="no-referrer" :src="item.cardImg" />
        <div class="card_body flex-col">
          <span class="body_card_title">{{ item.cardTitle }}</span>
          <div class="body_card_date">
            <div class="card_date_body flex-row justify-between">
              <div class="date_body_wrapper flex-col">
                <img class="date_body_image" referrerpolicy="no-referrer" :src="item.cardIcon" />
              </div>
              <span class="date_body_text">{{ item.cardDate }}</span>
            </div>
          </div>
          <span class="body_card_content" v-if="item.cardContent">{{ item.cardContent }}</span>
          <div class="body_card_btns flex-col">
            <span type="primary" class="card_btns_text" @click="convertUrl(item.cardUrl)">查看详情</span>
          </div>
        </div>
      </div>
    </div>
      <!-- 展开活动 -->
      <!-- <div class="group_more flex-row justify-between">
        <img class="more_image_header" referrerpolicy="no-referrer"
          src="https://lanhu-oss.lanhuapp.com/FigmaDDSSlicePNGdccbc4bcd0108349750f25c0313c6dfb.png" />
        <div class="more_body flex-row">
          <div class="body_content flex-row justify-between">
            <span class="body_content_text">展开查看更多活动</span>
            <img class="body_content_image" referrerpolicy="no-referrer"
              src="https://lanhu-oss.lanhuapp.com/FigmaDDSSlicePNG9a562aa0eaa40bfeb1a5763f9242461c.png" />
          </div>
        </div>
        <img class="more_image_footer" referrerpolicy="no-referrer"
          src="https://lanhu-oss.lanhuapp.com/FigmaDDSSlicePNG00c68c8ca20add92dc83f2d1002fd790.png" />
      </div> -->
    </div>
    <form-nav v-if="$webType != 'cbia'" />
    <footer-nav />
  </div>
</template>
<script>
import sectionCard1Img from '@/assets/images/aboutUs/section_card1.png';
import sectionCard2Img from '@/assets/images/aboutUs/section_card2.png';
import sectionCard3Img from '@/assets/images/aboutUs/section_card3.png';
import decorationIcon from '@/assets/images/home/decoration.png';
import correctOrangeIcon from '@/assets/images/home/corrent_orange.png';
import cardImg from '@/assets/images/aboutUs/card_background.png';
import cardImgHeader from '@/assets/images/aboutUs/card_img_header.png';
import cardImgContent from '@/assets/images/aboutUs/card_img_content.png';
import timeIcon from '@/assets/images/policyComment/time-icon.png';
export default {
  data() {
    return {
      decorationIcon,
      correctOrangeIcon,
      cardImg,
      cardImgContent,
      cardImgHeader,
      newsList: [
        {
          cardImg: sectionCard1Img,
          cardTitle: '《推进中国电池数字护照体系建设倡议书》',
          cardIcon: timeIcon,
          cardDate: '2023年2月4日 10:00:13',
          cardContent: '中国电池工业协会、中国信息通信研究院、宁德时代、中创新航、达索系统、正力新能、南京复创、弗迪电池、蜂巢能源、欣旺达电子、孚能科技、珠海冠宇等12家单位共同发布《推进中国电池数字护照体系建设倡议书》。',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/zh7-KmFDu4P6bG7TPL6MGQ'
        },
        { 
            cardImg: sectionCard2Img,
            cardTitle: '中国电池工业协会电池数字护照工作组第一次小组会议',
            cardIcon: timeIcon,
            cardDate: '2023年2月4日 10:00:13',
            cardContent: '中国电池工业协会电池数字护照工作组主要的工作职能包括：建立电池产业信息数据采集体系、建立并规范电池数字护照模型架构、建立并完善电池数字护照体系底层基础设施和推进企业进行电池数字护照落地应用。',
            cardBtn: '查看详情',
            cardUrl: 'https://mp.weixin.qq.com/s/DJfm6O5J2yICg5KzlAMpAw',  
        },
        {
          cardImg: sectionCard3Img,
          cardTitle: '中国电池工业协会联合发布《中国电池数字护照白皮书》',
          cardIcon: timeIcon,
          cardDate: '2023年2月4日 10:00:13',
          cardContent: '2024年4月20日，中国电池工业协会八届五次理事会暨电池护照工作推进会在北京顺利召开，协会理事及以上会员代表、分支机构代表、电池护照工作组有关成员及相关机构代表共计150余人参会。现场，中国电池工业协会刘宝生理事长和宁德时代第一党委书记孟祥峰共同揭牌发布《中国电池数字护照白皮书》。白皮书由中国电池工业协会联合中国信通院、江苏跨境数据科技、南京复创、TÜV莱茵、宁德时代、中创新航、欣旺达、正力新能、孚能科技、蜂巢能源、弗迪电池、珠海冠宇、达索系统共同编写，对中国电池数字护照体系建设内容、关键技术、实施路径、相关标准等方面进行系统阐述，对推动中国电池数字护照体系建设具有重要指导意义。',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/RvNqGQFpCGbCmt1Cftb1vg'
        }
      ]
    };
  },
  methods: {
    convertUrl(url) {
      window.open(url, '_blank')
    }
  },
};
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>