<template>
  <div class="page flex-col">
    <div class="aboutdpp-header flex-col">
      <span class="aboutdpp-header_text1">关于DPP</span>
      <span class="aboutdpp-header_text2">了解产品数字护照</span>
    </div>
    <div class="aboutdpp-group1 flex-col">
      <div class="anrchor_class" id="what"></div>
      <div class="aboutdpp-group1_title flex-row justify-between">
        <img class="group1_title-img" referrerpolicy="no-referrer"
          :src="decorationIcon" />
        <span class="group1_title-label">什么是产品数字护照？</span>
      </div>
      <div class="aboutdpp-group1_box flex-col">
        <div class="group1_box-block1 flex-row justify-between">
          <img class="box-block1_img" referrerpolicy="no-referrer"
            :src="passportOneImg" />
          <div class="box-block1_div flex-col justify-between">
            <span class="block1_div-text">DPP概念提出：促进数绿双转的重要抓手</span>
            <div class="block1_div-wrapper">
              <span class="div-wrapper_span1">目前，促进碳达峰碳中和已成为全球共识，数字技术赋能成为产业绿色低碳发展的重要路径。为促进数字化绿</span>
              <span class="div-wrapper_span2">色化协同发展，2022年3月欧盟在《可持续产品生态设</span>
              <span class="div-wrapper_span3">计法规》提案中明确提出了“产品数字护照”(Digital</span>
              <span class="div-wrapper_span4">Product&nbsp;Passport,DPP)概念和要求，主要采集核验产</span>
              <span class="div-wrapper_span5">品从设计、制造、物流、使用、回收所关联的各类绿色可持续信息。<br /></span>
            </div>
          </div>
        </div>
        <div class="group1_box-block2 flex-row justify-between">
          <div class="box-block2_wrapper flex-col justify-between">
            <span class="block2_wrapper-text1">欧盟《可持续产品生态设计法规》DPP性质概述</span>
            <span
              class="block2_wrapper-text2">产品数字护照的本质是一组特定于产品的、可通过数据载体进行电子访问的数据集，通过数据载体链接唯一标识符，在跨国贸易和流通中证明产品的产地、身份及可持续水平。</span>
          </div>
          <img class="image_4" referrerpolicy="no-referrer"
            :src="passportTwoImg" />
        </div>
      </div>
    </div>
    <div class="aboutdpp-group2 flex-col">
      <div class="anrchor_class" id="process"></div>
      <div class="aboutdpp-group2_title flex-row justify-between">
        <img class="group2_title-img" referrerpolicy="no-referrer"
             :src="decorationIcon" />
        <span class="group2_title-label">产品数字护照的实施流程</span>
      </div>
      <div class="group2_title-card flex-col">
        <img :src="imageProduct" />
      </div>
    </div>
    <div class="aboutdpp-group3 flex-col">
      <div class="anrchor_class" id="significance"></div>
      <div class="aboutdpp-group3_box1 flex-col"></div>
      <div class="aboutdpp-group3_box2 flex-col">
        <div class="group3_box2-block1 flex-col justify-between">
          <span class="box2-block1_text1">产品数字护照的意义有哪些？</span>
          <span class="box2-block1_text2">推动数字化转型，促进可持续循环经济</span>
        </div>
        <div class="group3_box2-block2 flex-row justify-between">
          <div class="box2-block2_div1 flex-col justify-between">
            <div :class="['block2_div1-section flex-row', activeIndex == index ? 'active' : null]"
              v-for="(item, index) in cards" :key="index" @click="cardTabItem(index)">
              <div class="div1-section_paragraph flex-col justify-between">
                <span class="section_paragraph-text1">{{ item.cardItemTitle }}</span>
                <span class="section_paragraph-text2">
                  {{ item.cardItemLabels[0] }}
                  <br />
                  {{ item.cardItemLabels[1] }}
                </span>
              </div>
            </div>
          </div>
          <div class="box2-block2_div2 flex-col">
            <img :src="imageCardShow" />
          </div>
        </div>
      </div>
    </div>
    <footer-nav />
  </div>
</template>
<script>
import imageProduct from '@/assets/images/aboutdpp/image_product.png';
import imageCardOne from '@/assets/images/aboutdpp/image_card_one.png';
import imageCardTwo from '@/assets/images/aboutdpp/image_card_two.png';
import imageCardThree from '@/assets/images/aboutdpp/image_card_three.png';
import decorationIcon from '@/assets/images/home/decoration.png';
import passportOneImg from '@/assets/images/aboutdpp/passport_one.png';
import passportTwoImg from '@/assets/images/aboutdpp/passport_two.png';
export default {
  name: 'aboutdpp',
  metaInfo: {
    meta: [{
      name: 'keyWords',
      content: ''
    },
    {
      name: 'description',
      content: ''
    }],
  },
  data() {
    return {
      decorationIcon,
      passportOneImg,
      passportTwoImg,
      imageProduct,
      activeIndex: 0,
      imageCardShow: imageCardOne,
      cards: [
        {
          cardItemTitle: '市场需求',
          cardItemLabels: [
            'DPP响应碳达峰碳中和需求',
            '为数字化绿色化协同发展打造新路径'
          ],
          cardItemImg: imageCardOne
        },
        {
          cardItemTitle: '行业趋势',
          cardItemLabels: [
            'DPP加速产业链数字化转型',
            'ESG数据深度整合成为趋势'
          ],
          cardItemImg: imageCardTwo
        },
        {
          cardItemTitle: '为全链路数字化运营赋能',
          cardItemLabels: [
            'DPP契合新兴信息技术演进',
            '为数字基础设施规模化应用构建新场景'
          ],
          cardItemImg: imageCardThree
        }
      ]
    }
  },
  methods: {
    cardTabItem(index) {
      this.activeIndex = index;
      this.imageCardShow = this.cards[index].cardItemImg;
    }
  },
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>