<template>
  <div class="portal">
    <template v-if="filterSepcialRoute">
    </template>
    <template v-else>
      <slider-nav />
      <div class="portal-content">
        <router-view />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'portal',
  data() {
    return {
    }
  },
  computed: {
    filterSepcialRoute() {
      return this.$route.name == 'download' && this.$webType == 'dpp'
    }
  }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>