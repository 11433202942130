<template>
  <div class="page flex-col">
    <div class="block_work flex-col">
      <div class="text-wrapper_1 flex-col">
        <span class="text_1">工作动态</span>
        <span class="text_2">Work&nbsp;dynamics</span>
      </div>
      <!-- 新闻列表 -->
      <div v-for="(item, index) in newsList" :key="index">
        <div class="group_news flex-row" v-if="item.cardUrl">
          <img class="image_card" referrerpolicy="no-referrer" :src="item.cardImg" />
          <div class="card_body flex-col">
            <span class="body_card_title">{{ item.cardTitle }}</span>
            <div class="body_card_date">
              <div class="card_date_body flex-row justify-between">
                <div class="date_body_wrapper flex-col">
                  <img class="date_body_image" referrerpolicy="no-referrer" :src="item.cardIcon" />
                </div>
                <span class="date_body_text">{{ item.cardDate }}</span>
              </div>
            </div>
            <span class="body_card_content" v-if="item.cardContent">{{ item.cardContent }}</span>
            <div class="body_card_btns flex-col">
              <span type="primary" class="card_btns_text" @click="convertUrl(item.cardUrl)">查看详情</span>
            </div>
          </div>
        </div>
      </div>
      <footer-nav />
    </div>
  </div>
</template>
<script>
import timeIcon from '@/assets/images/policyComment/time-icon.png';
import cardImg1 from '@/assets/images/workNews/card_img_1.png';
import cardImg2 from '@/assets/images/workNews/card_img_2.png';
import cardImg3 from '@/assets/images/workNews/card_img_3.png';
import cardImg4 from '@/assets/images/workNews/card_img_4.png';
import cardImg5 from '@/assets/images/workNews/card_img_5.png';
import cardImg6 from '@/assets/images/workNews/card_img_6.png';
import cardImg7 from '@/assets/images/workNews/card_img_7.png';
import cardImg8 from '@/assets/images/workNews/card_img_8.png';
import cardImg9 from '@/assets/images/workNews/card_img_9.png';
import cardImg10 from '@/assets/images/workNews/card_img_10.png';
import cardImg11 from '@/assets/images/workNews/card_img_11.png';
import workNewsCover1 from '@/assets/images/workNews/workNewsCover1.png';
import workNewsCover2 from '@/assets/images/workNews/workNewsCover2.png';
import workNewsCover3 from '@/assets/images/workNews/workNewsCover3.png';
import workNewsCover4 from '@/assets/images/workNews/workNewsCover4.png';
import workNewsCover5 from '@/assets/images/workNews/workNewsCover5.png';
export default {
  data() {
    return {
      newsList: [
        {
          cardImg: workNewsCover1,
          cardTitle: '为可持续发展赋能 | 南京复创正式加入全球电池联盟（GBA）',
          cardIcon: timeIcon,
          cardDate: '2024年01月17日 11:31:00',
          cardContent: '2023年12月，全球电池联盟（GBA）正式批准南京复创作为全球组织的新成员加入。作为IT提供商（ IT service providers），南京复创将积极参与GBA的项目，为共同打造全球循环、负责任和公正的电池价值链贡献科技力量。',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/ZdW5tUF7X04gF5XhEgi7Kg'
        },
        {
          cardImg: workNewsCover2,
          cardTitle: '复创大事记 | 人民网：一块锂电池的逐“绿”之旅',
          cardIcon: timeIcon,
          cardDate: '2024年09月04日 13:56:00',
          cardContent: '人民网编者按: 千万IP创科普，共筑科技强国梦。为普及科学知识，弘扬科学精神，提高全民科学文化素质，助力实现高水平科技自立自强，中国科协科普部与人民网联合策划推出“见证科技之路”主题报道，聚焦国家科技战略政策、重要科技奖项、“卡脖子”关键核心技术等，深入基层一线开展采访调研，推出一批生动鲜活的科普精品，大力弘扬科学家精神，为建设科技强国汇聚磅礴力量。',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/QrcXuybfgOudmlqb6Cyv3w'
        },
        {
          cardImg: workNewsCover3,
          cardTitle: '企业风采 | 南京复创入选Pegasus投融研究所《产品数字护照行业研究报告》',
          cardIcon: timeIcon,
          cardDate: '2024年06月28日 09:00:00',
          cardContent: '近日，天马科创投（Pegasus Tech Ventures）正式发布《产品数字护照行业研究报告》。南京复创与英国 Circulor、加拿大 OPTEL 公司、德国 Spherity、荷兰Circularise等全球领先产业链及数字护照服务商作为重点企业代表入选《报告》。',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/nBjOEHOiwTxThebM5V8uQw'
        },
        {
          cardImg: workNewsCover4,
          cardTitle: '活动回顾丨南京复创参与中国电池工业协会电池护照工作推进会并做主题汇报',
          cardIcon: timeIcon,
          cardDate: '2024年04月20日 19:21:00',
          cardContent: '2024年4月20日，中国电池工业协会八届五次理事会暨电池护照工作推进会在北京圆满举办，工信部消费品工业司副司长李强、中国电池工业协会理事长刘宝生、中国电池工业协会会员部主任吴丹、南京玄武高新技术产业集团有限公司副总经理王涛、中国电池工业协会综合业务部副主任宋翊、南京复创董事长付金国等数百位行业专家与企业代表参与会议。',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/yWEVK1DBbXoVbsKwhJmmrw'
        },
        {
          cardImg: workNewsCover5,
          cardTitle: '2024 星火生态大会｜绿色低碳数字创新研讨会成功举办，南京复创受邀出席并做主题汇报',
          cardIcon: timeIcon,
          cardDate: '2024年04月19日 10:13:00',
          cardContent: '4月18日，由中国信息通信研究院（以下简称“中国信通院”）主办的“绿色低碳数字创新研讨会”在“信任与智能”2024星火生态大会期间召开。研讨会邀请了来自产学研用的专家学者，以产品数字护照作为专题之一，凝聚发展共识，启发跨界思想碰撞，以数绿融合发展推进新型工业化。会上发布了中国产品数字护照发展路线图。',
          cardBtn: '查看详情',
          cardUrl: 'https://mp.weixin.qq.com/s/2t0wuLoBYuzyn_hIkZu2Fw'
        },
        
      ],
      // newsList: [
      //   {
      //     cardImg: cardImg1,
      //     cardTitle: '中国电池工业协会八届五次理事会暨电池护照工作推进会圆满召开',
      //     cardIcon: timeIcon,
      //     cardDate: '2024年04月21日 21:46:00',
      //     cardContent: '',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/RvNqGQFpCGbCmt1Cftb1vg'
      //   },
      //   {
      //     cardImg: cardImg2,
      //     cardTitle: '浙江发布首个行业级产品质量数字护照应用',
      //     cardIcon: timeIcon,
      //     cardDate: '2023年11月09日 19:43:00',
      //     cardContent: '11月2日下午，由浙江省台州市椒江区人民政府主办，椒江区市场监督管理局、杰克科技股份有限公司、牙木科技股份有限公司联合承办的“缝制设备产品质量数字护照”项目发布会在台州椒江举行。',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/5QXrWDqmsCLCWjtwUhSI4g'
      //   },
      //   {
      //     cardImg: cardImg3,
      //     cardTitle: '艾利丹尼森推出针对欧盟数字产品护照的服务（DPPaaS），推进零售数字化进程',
      //     cardIcon: timeIcon,
      //     cardDate: '2023年11月30日 12:03:00',
      //     cardContent: '',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/mFCq5ScO07IQ8OkETrmOfw'
      //   },
      //   {
      //     cardImg: cardImg4,
      //     cardTitle: '美妆产品也有“护照”了，资生堂率先布局DPP | 全球观察',
      //     cardIcon: timeIcon,
      //     cardDate: '2024年02月24日 18:02:00',
      //     cardContent: '近日，资生堂旗下品牌Ulé推出新品C-Bright精华，这也是美妆品牌中首批布局“数字产品护照”的品牌。',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/h5nssJ3b2bQYk8wMg2oPFg'
      //   },
      //   {
      //     cardImg: cardImg5,
      //     cardTitle: '【电池护照】欧盟法规背景下，构建我国安全自主可控电池数字护照体系迫在眉睫！',
      //     cardIcon: timeIcon,
      //     cardDate: '2023年10月30日 10:31:00',
      //     cardContent: '',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/Vl1gmZlMYS9JLZvU43r2Iw'
      //   },
      //   {
      //     cardImg: cardImg6,
      //     cardTitle: '国内首个电池数字护照工作组正式开始组建',
      //     cardIcon: timeIcon,
      //     cardDate: '2023年12月27日 17:19:00',
      //     cardContent: '10月协会在北京组织召开欧盟《电池与废电池法规》座谈会，邀请发改委、工信部、商务部等相关司局领导、行业专家及电池企业代表共商应对策略。',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/4TQotVxUMbzaD2g4uBF4xA'
      //   },
      //   {
      //     cardImg: cardImg7,
      //     cardTitle: '中国信通院携手中国电池工业协会等12家单位联合发布《推进中国电池数字护照体系建设倡议书》',
      //     cardIcon: timeIcon,
      //     cardDate: '2023年12月06日 18:28:00',
      //     cardContent: '',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/zh7-KmFDu4P6bG7TPL6MGQ'
      //   },
      //   {
      //     cardImg: cardImg8,
      //     cardTitle: '新闻速递 | 中国电池数字护照工作组筹备会议成功召开',
      //     cardIcon: timeIcon,
      //     cardDate: '2023年12月08日 09:30:00',
      //     cardContent: '自2027年2月18日起，每一块LMT电池、每一块容量大于2千瓦时的工业电池以及每一块投放市场或投入使用的电动汽车电池都应该有电子记录，即电池护照。包括其化学成.....',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/DJfm6O5J2yICg5KzlAMpAw'
      //   },
      //   {
      //     cardImg: cardImg9,
      //     cardTitle: '《电池护照指南》团体标准启动研讨会顺利召开',
      //     cardIcon: timeIcon,
      //     cardDate: '2024年01月26日 10:05:00',
      //     cardContent: '1月25日，由深圳市工业和信息化局、深圳市市场监督管理局质量处和标准处指导，中国化学与物理电源行业协会（简称“协会”）主办深圳普瑞赛思承办。',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/Zy-Q45wViMYH7n7lPFDuRg'
      //   },
      //   {
      //     cardImg: cardImg10,
      //     cardTitle: '2023世界智能制造大会丨电池专题论坛成功举办，推进电池产业高质量发展',
      //     cardIcon: timeIcon,
      //     cardDate: ' 2023年12月07日 15:59:00',
      //     cardContent: '',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/eNIfCXW2m3Lh8bZlmzwNMA'
      //   },
      //   {
      //     cardImg: cardImg11,
      //     cardTitle: '喜报！复创电池产品数字护照荣获“人民匠心产品奖”',
      //     cardIcon: timeIcon,
      //     cardDate: '2023年12月29日 20:53:00',
      //     cardContent: '12月29日，在2023人民质量高峰论上，第二十届人民匠心奖获奖名单正式揭晓。南京复创“电池产品数字护照”获人民匠心产品奖，与中国40家知名头部企业及产品并列！',
      //     cardBtn: '查看详情',
      //     cardUrl: 'https://mp.weixin.qq.com/s/dYwRuB84flSIV2SV60ko7g'
      //   },
      // ]
    };
  },
  methods: {
    convertUrl(url) {
      window.open(url, '_blank')
    }
  },
};
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
