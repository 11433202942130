<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: '',
  metaInfo: {},
  components: {},
  mounted() { },
  methods: {},
}
</script>
<style lang="less" scoped></style>