<template>
  <div class="page flex-col">
    <div class="carbon-header flex-col">
      <span class="carbon-header_text1">企业碳管理</span>
      <span class="carbon-header_text2">Enterprise&nbsp;carbon&nbsp;management</span>
    </div>
    <div class="carbon-middle flex-col">
      <div class="carbon-middle_title flex-row justify-between">
        <div class="anrchor_class" id="challenge"></div>
        <img class="middle_title-img" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="middle_title-label">企业正面临着碳管理的挑战</span>
      </div>
      <div class="carbon-middle_group1 flex-row justify-between">
        <div :class="['middle_group1-box flex-row justify-between', tabIndex == item.tab ? 'active' : null]"
          v-for="(item, index) in tabs" :key="index" @mouseenter="tabClick(index)">
          <img :class="['group1-box_img']" referrerpolicy="no-referrer"
            :src="tabIndex == item.tab ? item.activeIcon : item.icon" />
          <span class="group1-box_label">{{ item.label }}</span>
        </div>
      </div>
      <div :class="['carbon-middle_group2 flex-row']">
        <img :class="[tabIndex == 0 ? 'animation_show' : 'anmiation_hide']"  :src="ImgOuter" />
        <img :class="[tabIndex == 1 ? 'animation_show' : 'anmiation_hide']" :src="ImgInner" />
      </div>
      <div class="carbon-middle_title1 flex-row justify-between">
        <div class="anrchor_class" id="application"></div>
        <img class="middle_title1-img" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="middle_title1-label">应用场景</span>
      </div>
      <div class="carbon-middle_group3 flex-col justify-between">
        <div class="middle_group3-box flex-row justify-between" v-for="(arr, index) in getComputedCards" :key="index">
          <div class="group3-box_block flex-col align-center" v-for="(item, subIndex) in arr" :key="subIndex">
            <div class="box_block-item flex-row">
              <div :class="['block-item_wrapper flex-col', subIndex == 0 ? 'margin-left_0' : 'margin-left_1']">
                <img class="item_wrapper-img" referrerpolicy="no-referrer" :src="item.cardIcon" />
              </div>
              <div class="block-item_text flex-col justify-between">
                <span class="item_text-one">{{ item.cardTitle }}</span>
                <span class="item_text-two">{{ item.cardLabel }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carbon-bottom flex-col">
      <div class="carbon-bottom_title flex-row">
        <div class="bottom_title-box flex-row justify-between">
          <div class="anrchor_class" id="coreValue"></div>
          <img class="title-box_img" referrerpolicy="no-referrer" :src="decorationIcon" />
          <span class="title-box_label">核心价值</span>
        </div>
      </div>
      <div class="carbon-bottom_group flex-row justify-between">
        <div :class="['bottom_group-box flex-col', listItemActive == index ? 'active' : null]"
          v-for="(item, index) in list" :key="index" @click="tabListItem(index)">
          <img class="group-box_img" referrerpolicy="no-referrer" :src="item.listItemIcon" />
          <span class="group-box_label">{{ item.listItemText }}</span>
        </div>
      </div>
    </div>
    <footer-nav />
  </div>
</template>
<script>
import ImgInner from '@/assets/images/carbonManage/inner_img.png';
import ImgOuter from '@/assets/images/carbonManage/outer_img.png';
import IconOuter from '@/assets/images/carbonManage/outer_icon.png';
import IconActiveOuter from '@/assets/images/carbonManage/outer_active_icon.png';
import IconInner from '@/assets/images/carbonManage/inner_icon.png';
import IconActiveInner from '@/assets/images/carbonManage/inner_active_icon.png';
import decorationIcon from '@/assets/images/home/decoration.png';

import sceneIconOne from '@/assets/images/carbonManage/scene_icon_one.png';
import sceneIconTwo from '@/assets/images/carbonManage/scene_icon_two.png';
import sceneIconThree from '@/assets/images/carbonManage/scene_icon_three.png';
import sceneIconFour from '@/assets/images/carbonManage/scene_icon_four.png';
import sceneIconFive from '@/assets/images/carbonManage/scene_icon_five.png';
import sceneIconSix from '@/assets/images/carbonManage/scene_icon_six.png';
import sceneIconSeven from '@/assets/images/carbonManage/scene_icon_seven.png';
import sceneIconEight from '@/assets/images/carbonManage/scene_icon_eight.png';

import valueIconOne from '@/assets/images/carbonManage/value_icon_one.png';
import valueIconTwo from '@/assets/images/carbonManage/value_icon_two.png';
import valueIconThree from '@/assets/images/carbonManage/value_icon_three.png';
import valueIconFour from '@/assets/images/carbonManage/value_icon_four.png';
export default {
  data() {
    return {
      decorationIcon,
      ImgInner,
      ImgOuter,
      tabIndex: 0,
      listItemActive: 0,
      tabs: [
        { tab: 0, icon: IconOuter, activeIcon: IconActiveOuter, label: '外部挑战' },
        { tab: 1, icon: IconInner, activeIcon: IconActiveInner, label: '内部挑战' }
      ],
      cards: [
        {
          cardIcon: sceneIconOne,
          cardTitle: '能碳数字空间构建',
          cardLabel: '基于碳排放数据与物理世界关系梳理，应用数字孪生技术构建全景能碳数字空间，帮助企业全面且实时地掌握相关节点碳排放情况，为企业碳管理提供可视化数据底座。'
        },
        {
          cardIcon: sceneIconTwo,
          cardTitle: '组织碳测算',
          cardLabel: '基于国内外碳核算标准，科学测算企业碳排放，帮助企业掌握组织运营、产品生产等多层面的实际碳排放情况。'
        },
        {
          cardIcon: sceneIconThree,
          cardTitle: '产品碳建模',
          cardLabel: '基于国内外碳足迹边界标准及企业实际需求，提供多边界生命周期碳足迹建模选择，通过数据库和模型库辅助算法，实现拖拽式智能建模。'
        },
        {
          cardIcon: sceneIconFour,
          cardTitle: '碳足迹评价',
          cardLabel: '基于国际碳足迹核算标准，合理测算特定产品不同生命周期碳足迹，帮助企业掌握各类产品在不同生命周期情况下的碳足迹环境影响结果信息。'
        },
        {
          cardIcon: sceneIconFive,
          cardTitle: '碳数据分析',
          cardLabel: '针对测算碳排放数据，通过横向纵向对比分析，识别相对高碳排环节，分析减排关键因素，帮助企业深入了解自身碳排放结构，科学制定碳排放目标。'
        },
        {
          cardIcon: sceneIconSix,
          cardTitle: '合规校验',
          cardLabel: '针对欧盟循环经济法案、欧盟碳边境调节机制等外部环境规制要求，帮助企业分析当前合规进展，识别合规潜在风险，出具相应的合规报告。'
        },
        {
          cardIcon: sceneIconSeven,
          cardTitle: '碳减排管理',
          cardLabel: '围绕碳减排目标，结合碳数据分析情况，辅助企业制定科学减排路径，提供减排工作跨部门协同监管工具，保障工作成效。'
        },
        {
          cardIcon: sceneIconEight,
          cardTitle: '供应链碳管理',
          cardLabel: '基于供应链碳减排需求，制定供应链碳管理绩效评估体系，通过为供应链碳管理及减排工作提供小程序等多路径服务，实现供应链协同减排。'
        }
      ],
      list: [
        {
          listItemIcon: valueIconOne,
          listItemText: '利用数字孪生技术打造全价值链能碳实景空间，挖掘企业碳数据资产价值'
        },
        {
          listItemIcon: valueIconTwo,
          listItemText: '形成科学应对内外部环境挑战的企业碳管理体系，保障企业零碳转型'
        },
        {
          listItemIcon: valueIconThree,
          listItemText: '完成碳管理工作数字工具化，支持跨部门协同工作，落实企业节能减排规划部署'
        },
        {
          listItemIcon: valueIconFour,
          listItemText: '实现供应链协同降碳，提升全价值链零碳竞争力，传递企业可持续发展理念'
        }
      ]
    };
  },
  computed: {
    getComputedCards() {
      let arr = [];
      return this.cards.reduce((cur, prev, index) => {
        arr = [...arr, prev];
        if (index % 2 == 1 || index == this.cards.length - 1) {
          cur = [...cur, arr];
          arr = [];
        }
        return cur;
      }, []);
    }
  },
  mounted() {
  },
  methods: {
    tabClick(index) {
      this.tabIndex = index;
    },
    tabListItem(index) {
      this.listItemActive = index;
    }
  },
};
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>